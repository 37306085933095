import Button from "components/buttonNew/button";

export const JoinButton = ({ onClickJoin }: { onClickJoin: () => void }) => {
  return (
    <Button
      size={"medium"}
      className="w-full min-w-[220px] md:w-auto"
      onClick={onClickJoin}
    >
      Join now
    </Button>
  );
};
