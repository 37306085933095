import type { Dispatch, SetStateAction } from "react";
import classNames from "classnames";

// TODO: remove unused styles from registration.module.css
import styles from "../registration.module.css";
import { DynamicCheckboxText } from "./dynamicCheckboxText";
import type { TalkData } from "content-ui/talks/types";

type CheckboxAndTermProps = {
  talk: TalkData;
  setChecked: Dispatch<SetStateAction<boolean>>;
  isOptional?: boolean;
};

const CheckboxAndTerm = ({
  talk,
  setChecked,
  isOptional,
}: CheckboxAndTermProps) => {
  return (
    <div className={classNames("flex")}>
      <label
        htmlFor={`reports_third_party${isOptional ? "-optional" : ""}-${
          talk.id
        }`}
        className={classNames(styles.checkbox, "flex text-xs")}
      >
        <input
          id={`reports_third_party${isOptional ? "-optional" : ""}-${talk.id}`}
          data-testid={`${isOptional ? "optional-" : ""}consent`}
          type="checkbox"
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(e.target.checked);
          }}
        />
        <span className={"mr-md"}></span>
        <DynamicCheckboxText
          text={!isOptional ? talk?.checkboxText : talk?.optionalCheckboxText}
          sponsorName={!isOptional ? talk?.sponsorName : undefined}
        />
      </label>
    </div>
  );
};

type CheckboxAndTermsProps = {
  talk: TalkData;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  setIsCheckedOptional: Dispatch<SetStateAction<boolean>>;
};

export const CheckboxAndTerms = ({
  talk,
  setIsChecked,
  setIsCheckedOptional,
}: CheckboxAndTermsProps) => {
  return (
    <div className={`flex flex-col`}>
      {talk?.optionalCheckboxText && (
        <CheckboxAndTerm
          talk={talk}
          setChecked={setIsCheckedOptional}
          isOptional={true}
        />
      )}
      {talk?.checkboxText && (
        <CheckboxAndTerm talk={talk} setChecked={setIsChecked} />
      )}
    </div>
  );
};
