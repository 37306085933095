export const OutlookIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Add to Outlook Calendar</title>
    <path
      d="M8.67188 3.15625H13.2031C13.2865 3.15625 13.3802 3.20833 13.4844 3.3125C13.6094 3.41667 13.6719 3.54167 13.6719 3.6875L9.70312 6.34375C9.66146 6.34375 9.61979 6.34375 9.57812 6.34375L8.67188 5.78125V3.15625ZM8.67188 6.75L9.51562 7.3125C9.55729 7.33333 9.59896 7.34375 9.64062 7.34375C9.68229 7.34375 9.71354 7.34375 9.73438 7.34375L9.76562 7.3125C9.72396 7.33333 10.026 7.13542 10.6719 6.71875C11.3177 6.28125 11.9844 5.84375 12.6719 5.40625L13.6719 4.75V9.53125C13.6719 9.78125 13.599 9.96875 13.4531 10.0938C13.3281 10.2188 13.1615 10.2812 12.9531 10.2812H8.67188V6.75ZM4.32812 5.53125C4.59896 5.53125 4.80729 5.66667 4.95312 5.9375C5.11979 6.1875 5.20312 6.54167 5.20312 7C5.20312 7.45833 5.11979 7.8125 4.95312 8.0625C4.80729 8.3125 4.58854 8.4375 4.29688 8.4375C4.02604 8.4375 3.80729 8.3125 3.64062 8.0625C3.47396 7.79167 3.39062 7.4375 3.39062 7C3.39062 6.5625 3.47396 6.20833 3.64062 5.9375C3.80729 5.66667 4.03646 5.53125 4.32812 5.53125ZM0.328125 1.84375L8.17188 0.34375V13.6562L0.328125 12.0312V1.84375ZM4.29688 9.3125C4.81771 9.3125 5.24479 9.09375 5.57812 8.65625C5.91146 8.21875 6.07812 7.65625 6.07812 6.96875C6.07812 6.28125 5.91146 5.72917 5.57812 5.3125C5.26562 4.875 4.84896 4.65625 4.32812 4.65625C3.78646 4.65625 3.34896 4.875 3.01562 5.3125C2.68229 5.75 2.51562 6.33333 2.51562 7.0625C2.51562 7.72917 2.68229 8.27083 3.01562 8.6875C3.34896 9.10417 3.77604 9.3125 4.29688 9.3125Z"
      fill="currentColor"
    />
  </svg>
);
export const GoogleIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Add to Google Calendar</title>
    <path
      d="M7.15625 6.0625V6.03125H13.4375C13.5 6.28125 13.5312 6.65625 13.5312 7.15625C13.5312 9.09375 12.9375 10.6667 11.75 11.875C10.5833 13.0625 9.04167 13.6562 7.125 13.6562C5.29167 13.6562 3.71875 13.0104 2.40625 11.7188C1.11458 10.4271 0.46875 8.85417 0.46875 7C0.46875 5.14583 1.11458 3.57292 2.40625 2.28125C3.69792 0.989583 5.27083 0.34375 7.125 0.34375C8.9375 0.34375 10.4375 0.9375 11.625 2.125L9.84375 3.875C9.15625 3.1875 8.26042 2.84375 7.15625 2.84375C6.03125 2.84375 5.0625 3.26042 4.25 4.09375C3.45833 4.90625 3.0625 5.89583 3.0625 7.0625C3.0625 8.20833 3.45833 9.1875 4.25 10C5.0625 10.8125 6.03125 11.2188 7.15625 11.2188C7.80208 11.2188 8.375 11.125 8.875 10.9375C9.375 10.7292 9.76042 10.4688 10.0312 10.1562C10.3229 9.84375 10.5417 9.54167 10.6875 9.25C10.8333 8.9375 10.9375 8.64583 11 8.375H7.15625V6.0625Z"
      fill="currentColor"
    />
  </svg>
);
