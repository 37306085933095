import { useState } from "react";
import { default as NextImage, ImageProps } from "next/image";
import classNames from "classnames";
import { TRANSPARENT_PIXEL } from "lib/constants/shared";
import type { Modify } from "lib/types/common";

type CustomImageProps = Modify<
  ImageProps,
  {
    blurUp?: boolean;
  }
>;

const Image = ({
  src,
  alt,
  className,
  width = 1440,
  height = 810,
  blurDataURL,
  sizes,
  quality,
  blurUp = true,
  ...props
}: CustomImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  function imageLoader({
    src,
    width,
    quality,
  }: {
    src: string;
    width: number;
    quality: number;
  }): string {
    const url = new URL(src);
    const params = url.searchParams;

    params.set("auto", params.getAll("auto").join(",") || "format");
    params.set("fit", params.get("fit") || "max");
    params.set("w", params.get("w") || width.toString());

    if (quality) {
      params.set("q", quality.toString());
    }

    return url.href;
  }

  if (!src) return null;
  return (
    <NextImage
      onLoad={() => setIsLoaded(true)}
      loader={props.loader || imageLoader}
      src={src}
      alt={alt || ""}
      width={width}
      height={height}
      quality={quality || 75}
      blurDataURL={blurDataURL || TRANSPARENT_PIXEL}
      placeholder={"blur"}
      className={classNames(className, {
        "will-change-[filter] [transition:filter_0.25s]": blurUp,
        "blur-[10px]": !isLoaded && blurUp,
      })}
      sizes={
        sizes || "(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
      }
      {...props}
    />
  );
};

export default Image;
