import classNames from "classnames";

type StaticTagProps = {
  text: string;
  color?: "cerise" | "blue" | "grey";
  className?: string;
  noWrap?: boolean;
};

// TODO: The component design for variants has not been documented for this yet, so will need to be added
export const StaticTag = ({
  text,
  color = "cerise",
  className = "",
  noWrap = true,
}: StaticTagProps) => {
  return (
    <div data-testid="static-tag" className={classNames(className, "")}>
      <div
        data-testid={"static-tag__inner"}
        className={classNames("h-max-content px-[6px] py-1", {
          "w-max-content whitespace-nowrap": noWrap,
          "text-pretty max-w-fit": !noWrap,
          "bg-[rgba(216,28,94,0.1)]": color === "cerise",
          "bg-[rgba(51,80,219,0.1)]": color === "blue",
          "bg-[rgba(2,2,49,0.1)]": color === "grey",
        })}
      >
        <p
          className={classNames("text-[14px]", {
            "text-cerise": color === "cerise",
            "text-pro-blue": color === "blue",
            "text-black-rock": color === "grey",
          })}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
