import { WarningIcon } from "components/icons/icons";

export const ErrorMessage = () => {
  return (
    <div className={`flex items-center`}>
      <span className="mx-xs inline-block">
        <WarningIcon />
      </span>
      <p className="text-[14px] text-[#E52900] ">
        Please tick to agree with our event terms
      </p>
    </div>
  );
};
