import { formatTime, getDateStringWeekday, upcomingStartEndString } from "lib/utils";
import type { TalkData } from "./types";

export const setOpacity = (hex: string, alpha: string | number) =>
  `${hex}${Math.floor(Number(alpha) * 255)
    .toString(16)
    .padStart(2, "0")}`;

export const sectorColours = {
  Fintech: "00606F",
  "Startup Life": "EE4036",
  "Venture Capital": "422FCE",
  "Climate Tech": "006729",
};

export const bgColours = {
  Fintech: "DAEFF2",
  "Startup Life": "FFF0E0",
  "Venture Capital": "E4E1F7",
  "Climate Tech": "E6F2EB",
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function talkEndTime(dateTime: string | Date, duration: number) {
  const talkTime = new Date(dateTime);
  const talkDuration = duration * 60 * 1000;
  return new Date(talkTime.getTime() + talkDuration).getTime();
}

export const upcomingTalks = (data: TalkData[]) => {
  const now = new Date();

  return data
    .filter(
      (talk) => now.getTime() <= talkEndTime(talk?.dateTime, talk?.duration)
    )
    .sort(
      (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
    );
};

export const pastTalks = (data: TalkData[]) => {
  const now = new Date();

  return data
    .filter((talk) => {
      return (
        talk?.includeInPastEvents &&
        now.getTime() > talkEndTime(talk?.dateTime, talk?.duration)
      );
    })
    .sort(
      (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
    );
};


const inviteCopy = {
  linkInfo: {
    online:
      "Use the link below to join the discussion.\nhttps://sifted.eu/talks/",
  },
  contactInfo: {
    online:
      "Have any questions for our panellists? You can submit these ahead of time by emailing talks@sifted.eu.\n\nWe look forward to seeing you for this Sifted Talks.",
    memberOnline:
      "Have any questions about this event? You can submit these ahead of time by emailing members@sifted.eu.\n\nWe look forward to seeing you at this event!",
    inPerson:
      "Have any questions about this event? You can let us know by emailing talks@sifted.eu.\n\nWe look forward to seeing you at this event!",
    memberInPerson:
      "Have any questions about this event? You can let us know by emailing members@sifted.eu.\n\nWe look forward to seeing you at this event!",
  },
};

// TODO: refactor this file, as we no longer use things like isMemberOnly
export const generateTalkCalendarLinks = (talk: TalkData) => {
  const talkTime = new Date(talk.dateTime);
  const talkDuration = talk.duration * 60 * 1000;
  const endTime = new Date(talkTime.getTime() + talkDuration);

  const isMemberOnly = talk.isMemberOnly;
  const isInPerson = talk.isInPerson || false;

  const renderLinkInfo = () => {
    if (isInPerson) {
      return "";
    } else {
      return inviteCopy.linkInfo.online + talk.slug + "\n\n";
    }
  };

  const renderContactInfo = () => {
    if (!isMemberOnly && isInPerson) {
      return inviteCopy.contactInfo.inPerson + "\n";
    }
    if (!isMemberOnly && !isInPerson) {
      return inviteCopy.contactInfo.online + "\n";
    }
    if (isMemberOnly && isInPerson) {
      return inviteCopy.contactInfo.memberInPerson + "\n";
    }
    if (isMemberOnly && !isInPerson) {
      return inviteCopy.contactInfo.memberOnline + "\n";
    }
  };

  const location = talk.location || "Zoom virtual event";

  const trailingPeriod =
    ["?", "."].indexOf(talk.title[talk.title.length - 1]) > -1;

  const entryText = `Thank you for registering for our upcoming Sifted Talks, ${talk.title
    }${trailingPeriod ? "" : "."}\n\nDate: ${getDateStringWeekday(
      talkTime
    )}\nTime: ${upcomingStartEndString(talkTime, endTime)}\n\n${talk.aboutTheEvent
    }\n\n${renderLinkInfo()}${renderContactInfo()}`;

  return {
    google: `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formatTime(
      talkTime
    )}/${formatTime(endTime)}&location=${encodeURIComponent(
      location
    )}&text=${encodeURIComponent(talk.title)}&details=${encodeURIComponent(
      entryText
    )}`,
    outlook: `https://outlook.live.com/owa/?rru=addevent&startdt=${formatTime(
      talkTime
    )}&enddt=${formatTime(endTime)}&location=${encodeURIComponent(
      location
    )}&subject=${encodeURIComponent(talk.title)}&body=${encodeURIComponent(
      entryText
    )}&allday=false&path=/calendar/view/Month`,
  };
};