type PreviewModeBannerProps = {
  exitSlug: string;
};

const PreviewModeBanner = ({ exitSlug }: PreviewModeBannerProps) => {
  return (
    <div className="relative top-[60px] z-50 h-[80px] w-full bg-[#ffc107] px-lg text-center md:px-2xl">
      <div className="flex h-full w-full items-center justify-center">
        <h1 className="mr-md w-full font-bold text-2xs leading-tight md:mr-xl md:w-[initial] md:text-base">
          You are in preview mode
        </h1>
        <a
          // This is /talks/api because of how the routing to content-ui is currently set up on Wordpress prod
          href={`/api/exit-preview?slug=${exitSlug}`}
          className="w-full rounded-md bg-black py-3 px-3 text-center font-bold text-2xs text-white md:w-auto md:px-6 md:py-3 md:text-xs"
        >
          Exit preview mode?
        </a>
      </div>
    </div>
  );
};

export default PreviewModeBanner;
