import Markdown from "components/markdown/markdown";

const DynamicText = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name: string;
}) => {
  if (children === "companyName") return <span>{name || ""}</span>;
  if (children === "*") return <span className="text-cerise">*</span>;
};

export const DynamicCheckboxText = ({
  text,
  sponsorName,
}: {
  text: string;
  sponsorName?: string;
}) => {
  return (
    <div className="flex items-center">
      <Markdown
        className={"text-[14px] leading-[130%] text-mono-40"}
        options={{
          forceBlock: true,
          overrides: {
            code: {
              component: DynamicText,
              props: {
                className: "",
                name: sponsorName,
              },
            },
          },
        }}
        text={text}
      />
    </div>
  );
};
