import { SubscriberEvent } from "content-ui/subscriberEvents/types";
import { GoogleIcon, OutlookIcon } from "./assets/icons";
import { generateSubscriberEventCalendarLinks } from "content-ui/subscriberEvents/utils";
import { generateTalkCalendarLinks } from "content-ui/talks/utils";
import { TalkData } from "content-ui/talks/types";

export const AddToCalendar = ({
  event,
  classNames,
  type,
}: {
  event: SubscriberEvent | TalkData;
  classNames?: string;
  type: "talk" | "subscriberEvent";
}) => {
  const calendarLinks =
    type === "subscriberEvent"
      ? generateSubscriberEventCalendarLinks(event as SubscriberEvent)
      : generateTalkCalendarLinks(event as TalkData);

  return (
    <div className={`${classNames} flex items-center`}>
      <span>
        <a
          href={calendarLinks.outlook}
          target="_blank"
          rel="noreferrer"
          data-testid={"outlookCalendarLink"}
        >
          <OutlookIcon />
        </a>
      </span>
      <span>
        <a
          href={calendarLinks.google}
          target="_blank"
          rel="noreferrer"
          data-testid={"googleCalendarLink"}
        >
          <GoogleIcon />
        </a>
      </span>
    </div>
  );
};
