import { useEffect, useState } from "react";

declare global {
  interface Window {
    tp: any;
  }
}

const getTalksRsvpList = async (): Promise<string[]> => {
  return await new Promise((resolve, _reject) => {
    if (typeof window !== "undefined") {
      window.tp = window.tp || [];
      window.tp.push([
        "init",
        function () {
          window.tp.pianoId.loadExtendedUser({
            extendedUserLoaded: function (data) {
              const rsvpList =
                data.custom_field_values
                  ?.filter((field) => field.field_name === "talkRsvp")[0]
                  ?.value?.split(";") || [];

              resolve(rsvpList);
            },
            formName: "talkrsvp",
          });
        },
      ]);
    }
  });
};

const hasRsvped =
  (rsvpList: string[]) =>
  (id: string | number): boolean => {
    if (typeof id === "number") {
      id = id.toString();
    }

    return rsvpList.includes(id);
  };

const useTalksRsvp = (): {
  isReady: boolean;
  rsvpList: string[] | null;
  hasRsvped: (id: string | number) => boolean;
} => {
  const [rsvpList, setRsvpList] = useState<string[] | null>(null);

  useEffect(() => {
    getTalksRsvpList().then(setRsvpList);
  }, []);

  return {
    isReady: rsvpList !== null,
    rsvpList,
    hasRsvped: hasRsvped(rsvpList || []),
  };
};

export default useTalksRsvp;
