import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import relativeTime from "dayjs/plugin/relativeTime";

import useTalksRsvp from "lib/hooks/useTalksRsvp";
import { useUserData } from "lib/store/user";
import type { TalkData } from "content-ui/talks/types";
import { CheckboxAndTerms } from "../checkboxAndTerms/checkboxAndTerms";
import { ErrorMessage } from "../errorMessage";
import { submitTalkJoin, submitTalkRegistration } from "../registerButtonUtils";
import { StaticTag } from "components/staticTag/staticTag";
import { talkEndTime } from "content-ui/talks/utils";
import { SponsorLogoAndText } from "components/eventCard/talkElements";
import { JoinButton } from "../buttons/joinButton";
import { RsvpButton } from "../buttons/rsvpButton";

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

const TalkRegistration = ({
  talk,
  immediateJoin = false,
  variant = "eventCard",
}: {
  talk: TalkData;
  immediateJoin?: boolean;
  variant?: "eventCard" | "singlePage";
}) => {
  const router = useRouter();

  // RSVPing
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isCheckedOptional, setIsCheckedOptional] = useState<boolean>(false);
  const { isReady, hasRsvped } = useTalksRsvp();
  const rsvped = hasRsvped(talk.id);
  const [rsvpSent, setRsvpSent] = useState<boolean>(rsvped);
  const [checkBoxError, setCheckBoxError] = useState(false);

  // Date & time
  const talkStartDateTime = dayjs(talk.dateTime);
  const talkEarliestJoinTime = talkStartDateTime.subtract(30, "minute");

  // Misc
  const { hasMissingInfo, readerSubscriptionLevel } = useUserData();
  const [isHydrated, setIsHydrated] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setIsHydrated(true);
  }, []);
  useEffect(() => {
    setRsvpSent(rsvped);
  }, [rsvped]);

  const handleRegistration = async () => {
    const registrationParams = {
      talk,
      marketingConsent: talk.checkboxText ? isChecked : null,
      optionalConsent: talk.optionalCheckboxText ? isCheckedOptional : null,
    };

    const missingInfo = await hasMissingInfo?.();
    if (window?.tp?.pianoId?.isUserValid() && !missingInfo) {
      submitTalkRegistration(registrationParams);
      setRsvpSent(true);
    } else {
      const utm_source = (router.query["utm_source"] as string) || "null";
      const utm_medium = (router.query["utm_medium"] as string) || "null";
      const utm_campaign = (router.query["utm_campaign"] as string) || "null";
      const utm_content = (router.query["utm_content"] as string) || "null";

      const params = new URLSearchParams({
        talkId: talk.id,
        marketingConsent: String(registrationParams.marketingConsent),
        optionalConsent: String(registrationParams.optionalConsent),
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        redirectUrl: window.location.href,
      });

      window.location.assign(`/members/login?${params}`);
    }
  };

  const onClickRegister = () => {
    const showCheckboxError = talk.checkboxText && !isChecked;

    if (showCheckboxError) {
      setCheckBoxError(true);
    } else {
      handleRegistration();
    }
  };

  const onClickJoin = () => {
    submitTalkJoin({ talkId: talk.id, talkName: talk.title });
    window.open(talk?.talkLink || "https://sifted.eu/talks");
  };

  const joinAvailable = () => {
    const currentTime = dayjs();
    if (now.getTime() >= talkEndTime(talk?.dateTime, talk?.duration))
      return false;
    if (currentTime >= talkEarliestJoinTime || immediateJoin) return true;
    return false;
  };

  const Divider = () => {
    const customDashedClasses = `h-[1px]
[background-image:_linear-gradient(to_right,_#8E8E99,_#8E8E99_2px,_transparent_2px,_transparent_6px)]
[background-size:_6px_2px]`;

    return (
      <div
        className={classNames("my-5", {
          [customDashedClasses]: variant === "eventCard",
          hidden: variant === "singlePage",
        })}
      ></div>
    );
  };

  const now = new Date();
  const isTalkEnded =
    now.getTime() >= talkEndTime(talk?.dateTime, talk?.duration);

  if (!isHydrated || isTalkEnded) return null;
  if (!isReady) return null;

  return (
    <div
      className={classNames("flex w-full flex-col", {
        "lg:flex-row": variant === "eventCard",
        "gap-y-5 md:flex-row md:gap-x-6 lg:gap-y-0": variant === "singlePage",
      })}
    >
      {!rsvpSent && <Divider />}

      {!rsvpSent ? (
        <div className="z-[1] flex flex-col md:flex-row md:gap-x-6">
          {talk.sponsorLogo && variant === "eventCard" ? (
            <div className="min-w-[146px] max-md:mb-2 lg:hidden">
              <SponsorLogoAndText image={talk.sponsorLogo} />
            </div>
          ) : null}

          <div className="flex flex-col">
            <CheckboxAndTerms
              talk={talk}
              setIsChecked={setIsChecked}
              setIsCheckedOptional={setIsCheckedOptional}
            />
            <div className="ml-7 mt-[6px]">
              {checkBoxError && !isChecked ? <ErrorMessage /> : null}
            </div>
          </div>
        </div>
      ) : null}

      <div
        className={classNames("z-[1] flex justify-center gap-4 lg:w-full", {
          "mt-5 items-center lg:mt-0 lg:justify-end": variant === "eventCard",
          "items-start lg:mt-0 lg:justify-end":
            variant === "singlePage" && !rsvpSent,
          "items-start lg:mt-0 lg:justify-start":
            variant === "singlePage" && rsvpSent,
        })}
      >
        {rsvpSent ? (
          joinAvailable() ? (
            <JoinButton onClickJoin={onClickJoin} />
          ) : (
            !isTalkEnded && (
              <div
                className={classNames("flex w-full ", {
                  "items-end justify-center md:justify-end lg:min-h-[62px]":
                    variant === "eventCard",
                })}
              >
                <StaticTag
                  noWrap={false}
                  className={classNames("lg:min-w-[160px]")}
                  text="You'll receive the event link in your inbox on the day."
                />
              </div>
            )
          )
        ) : (
          <RsvpButton
            talk={talk}
            onClickRegister={onClickRegister}
            rsvpSent={rsvpSent}
            userLevel={readerSubscriptionLevel}
          />
        )}
      </div>
    </div>
  );
};

export default TalkRegistration;
