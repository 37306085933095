import { trackEventOnMixpanel, postTalksEvent, trackGTMEvent } from "lib/utils";
import type { TalkData } from "content-ui/talks/types";

type TalkRegistrationParams = {
  talk: TalkData;
  marketingConsent: boolean | null;
  optionalConsent: boolean | null;
};

const submitTalkRegistration = ({
  talk,
  marketingConsent,
  optionalConsent,
}: TalkRegistrationParams) => {
  const talkId = talk.id;
  const talkName = talk.title;
  const talkSector = talk.marketLabel;

  const utmSource = new URLSearchParams(window.location.search).get(
    "utm_source"
  );
  const utmMedium = new URLSearchParams(window.location.search).get(
    "utm_medium"
  );
  const utmCampaign = new URLSearchParams(window.location.search).get(
    "utm_campaign"
  );
  const utmContent = new URLSearchParams(window.location.search).get(
    "utm_content"
  );

  trackGTMEvent({
    event: "talk registration",
    talkId,
    talkName,
    marketingConsent,
    optionalConsent,
    userId: window.tp?.pianoId?.getUser()?.uid || null
  });

  postTalksEvent({
    fields: {
      marketingConsent,
      optionalConsent,
      title: talkName,
      talkId,
      talkAction: "Registered",
      utmSource,
      utmMedium,
      utmCampaign,
      utmContent,
      path: window.location.pathname,
    },
  });

  trackEventOnMixpanel({
    eventName: "Talk RSVP",
    fields: {
      marketingConsent,
      optionalConsent,
      title: talkName,
      talkId,
      talkSector,
      talkAction: "TalkRSVP",
      path: window.location.pathname,
    },
  });
};

const submitTalkJoin = ({
  talkId,
  talkName,
}: {
  talkId: TalkData["id"];
  talkName: TalkData["title"];
}) => {
  trackGTMEvent({
    event: "talk join",
    talkId,
    talkName,
    userId: window.tp?.pianoId?.getUser()?.uid || null
  });
  postTalksEvent({
    fields: {
      title: talkName,
      talkId,
      talkAction: "Checked-in",
      utmSource: new URLSearchParams(window.location.search).get("utm_source"),
      path: window.location.pathname,
    },
  });
};

export { submitTalkJoin, submitTalkRegistration };
