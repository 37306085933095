import { postRequest } from "lib/fetchers/fetcher";
import { SubscriberEvent } from "./types";
import { formatTime, getDateStringWeekday, upcomingStartEndString } from "lib/utils";

export function isLessThan72HoursAway(dateObject: Date) {
  const currentTime = new Date().getTime();
  const dateObjectTime = dateObject.getTime();
  const difference = dateObjectTime - currentTime;
  const seventyTwoHoursInMs = 259200000; // (72 * 60 * 60 * 1000)

  // Note: this returns true if the time is in the past too
  if (difference < seventyTwoHoursInMs) return true;
  return false;
}

export function eventEndTime(dateTime: string | Date, duration: number) {
  const eventTime = new Date(dateTime);
  const eventDuration = duration * 60 * 1000;
  return new Date(eventTime.getTime() + eventDuration).getTime();
}

export const sortByOldestFirst = (array: SubscriberEvent[]) => {
  return [...array].sort(
    (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
  );
};

export const sortByLatestFirst = (array: SubscriberEvent[]) => {
  return [...array].sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
};

export const isPastEvent = ({ dateTime }) => new Date(dateTime).getTime() < Date.now();

export const isUpcomingOrCurrentEvent = ({ dateTime, duration }) => {
  const eventEndTime = new Date(dateTime).getTime() + duration * 60 * 1000;
  return new Date(eventEndTime).getTime() > Date.now();
};

/**
 * Send subscriber event rsvp / check-in or express interest
 */
export const subscriberEventTracking = ({
  fields,
}: {
  fields: { roundtableId: string, eventAction?: "Checked-in" }
}, type: "expressInterest" | "rsvpOrCheckIn"): Promise<any> => {
  let resolvePromise: { (arg0): void } | null = null;

  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
  });

  window.tp = window.tp || [];
  window.tp.push([
    "init",
    () => {
      const userCookie = window?.tp?.pianoId?.getToken();

      const params = new URLSearchParams({
        userCookie,
        ...fields,
      });

      const url = type === 'rsvpOrCheckIn' ? `/api/subscriptionUtils/tracking/subscriberEvent?${params}` : `/api/subscriptionUtils/tracking/subscriberEventExpressInterest?${params}`

      resolvePromise?.(postRequest(url, fields));
    },
  ]);

  return promise;
};

export const generateSubscriberEventCalendarLinks = (event: SubscriberEvent) => {
  const isConference = event.eventType === "Conference call";
  const isNetworking = event.eventType === "Networking";
  const isPitch = event.eventType === "Pitch";
  const isOnSite = event.virtualOnSite === "On-site";

  const eventTime = new Date(event.dateTime);
  const eventDuration = event.duration * 60 * 1000;
  const endTime = new Date(eventTime.getTime() + eventDuration);

  const location = event.locationAddress || "Zoom virtual event";

  const eventTypeString = () => {
    if (isConference) return "Sifted Pro Conference Call";
    if (isPitch) return "Sifted Pitches";
    if (isNetworking) return "Sifted Pro Networking Event";
  };

  const entryText = `Thank you for registering for our upcoming ${eventTypeString()} - ${event.title
    }\n\nDate: ${getDateStringWeekday(eventTime)}\nTime: ${upcomingStartEndString(
      eventTime,
      endTime
    )}${isOnSite
      ? "\nLocation: " +
      "<a href='" +
      event.locationLink +
      "'>" +
      event.locationAddress +
      "</a>\n\n"
      : "\n\n"
    }${event.about}\n\n${!isOnSite
      ? "Use the link below to join the discussion.\n" +
      "<a href='https://sifted.eu/pro/subscriber-events/" +
      event.slug + "'" +
      "'>https://sifted.eu/pro/subscriber-events/" +
      event.slug +
      "</a>\n\n"
      : ""
    }${isPitch
      ? "Would you like to apply for the pitches? You can submit these ahead of time by emailing <a href='pro@sifted.eu'>pro@sifted.eu</a>.\n\nWe look forward to seeing you for this Sifted Pitches."
      : isConference
        ? "Have a specific topic you would like to discuss? You can submit these ahead of time by emailing <a href='pro@sifted.eu'>pro@sifted.eu</a>.\n\nWe look forward to seeing you for this Sifted Pro Conference Call."
        : "If you have any questions regarding the event, please contact <a href='pro@sifted.eu'>pro@sifted.eu</a>\n\nWe look forward to seeing you at the event!"
    }
  `;

  return {
    google: `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formatTime(
      eventTime
    )}/${formatTime(endTime)}&location=${encodeURIComponent(
      location
    )}&text=${encodeURIComponent(event.title)}&details=${encodeURIComponent(
      entryText
    )}`,
    outlook: `https://outlook.live.com/owa/?rru=addevent&startdt=${formatTime(
      eventTime
    )}&enddt=${formatTime(endTime)}&location=${encodeURIComponent(
      location
    )}&subject=${encodeURIComponent(event.title)}&body=${encodeURIComponent(
      entryText
    )}&allday=false&path=/calendar/view/Month`,
  };
};

export function eventIsMoreThanTenDaysAway(eventDate: Date) {
  const now = new Date();
  const timeDifference = eventDate.getTime() - now.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return daysDifference > 10;
}
